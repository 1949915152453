import Vue from 'vue'
import App from './App.vue'
import router from './plugins/app/_config/router'


// analytics
import WAnalytics from '@/plugins/w/analytics'
WAnalytics.addGoogleTagManager(router)


// 
import moment from 'moment'
moment.locale('sk')


//
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


//
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


// translations
import i18n  from '@/plugins/w/translate/index.js'
import  Translations  from  '@/plugins/w/translate/translations'

Translations.setup('sk', { // default language
	sk: 'Slovenský',
})

setTimeout(() => {
	Translations.setLocale('sk')
}, 200)

Vue.use(i18n)


// theme
import '@/plugins/app/_theme/app.sass'
import '@/plugins/app/_theme/z.sass'
import '@/plugins/w/universalcss/index.sass'
import '@/plugins/app/_config/axios'


// global
Vue.component('z-back-button', () => import('@/plugins/app@components/back-button/z-back-button.vue'))
Vue.component('w-input', () => import('@/plugins/w/input/w-input.vue'))
Vue.component('w-form-radio', () => import('@/plugins/w/input/w-form-radio.vue'))
Vue.component('z-reservation-calendar', () => import('@/plugins/app@components/calendar/z-reservation-calendar.vue'))


// 
import wToast from '@/plugins/w/toast'
Vue.prototype.$wToast = wToast


//
import store from './store'
Vue.config.productionTip = false
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
