import { Plugins } from '@capacitor/core'
const { Device, Storage } = Plugins

import i18n from '@/plugins/w/translate'
import moment from 'moment'

class Translations {
	private n = '🔴'
	private y = '🟢'

	private locale: any
	private defaultLocale: any
	private settings: any

	constructor() {
		this._loadOrGetLocale()
		moment.locale('sk')
	}

	setup = (defaultLOcale: any, settings: any) => {
		this.defaultLocale = defaultLOcale
		this.settings = settings
	}

	setLocale = (locale: any) => {
		this.locale = locale
		this._switchTranslationSign()
		i18n.locale = locale
		Storage.set({ key: 'locale', value: locale })
	}

	getLocale = () => {
		return this.locale
	}

	getLocales = () => {
		return this.settings
	}

	getDefaultLocale = () => {
		return this.defaultLocale
	}

	missingTranslation = (locale: any, key: any) => {
		return key
	}

	postTranslation = (string: any, key: any) => {
		return string === '' ? key : string
	}

	loadLocaleMessages() {
		const locales = require.context(
			'@/locales',
			true,
			/[A-Za-z0-9-_,\s]+\.json$/i
		)
		const messages = {}
		locales.keys().forEach((key) => {
			const matched = key.match(/([A-Za-z0-9-_]+)\./i)
			if (matched && matched.length > 1) {
				const locale = matched[1]
				messages[locale] = locales(key)
			}
		})

		return messages
	}

	_switchTranslationSign() {
		this.n = this.locale === this.defaultLocale ? '🟢' : '🔴'
	}

	private async _loadOrGetLocale() {
		let locale
		const storageLocale = await Storage.get({ key: 'locale' })

		if (!storageLocale.value) {
			const deviceLangCode = await Device.getLanguageCode()
			locale = deviceLangCode.value.split('-')[0]
		} else locale = storageLocale.value

		if (locale) this.locale = locale
		else this.locale = this.defaultLocale

		this.setLocale(this.locale)
	}
}

export default new Translations()

export const t = (text) => {
	return i18n.t(text)
}